exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-js": () => import("./../../../src/pages/~.js" /* webpackChunkName: "component---src-pages-js" */),
  "component---src-pages-minimal-js": () => import("./../../../src/pages/minimal.js" /* webpackChunkName: "component---src-pages-minimal-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-roldx-ai-js": () => import("./../../../src/pages/roldx_ai.js" /* webpackChunkName: "component---src-pages-roldx-ai-js" */),
  "component---src-pages-roldx-js": () => import("./../../../src/pages/roldx.js" /* webpackChunkName: "component---src-pages-roldx-js" */),
  "component---src-pages-salaries-js": () => import("./../../../src/pages/salaries.js" /* webpackChunkName: "component---src-pages-salaries-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

