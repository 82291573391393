import React from 'react';
import { Container } from '@material-ui/core';
import Layout from '../components/layout/layout';

import { css } from '@emotion/react';

const styles = (theme) => css`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #a4bac5;
  padding: 4rem 0;

  .iconImage {
    width: 50px;
    height: 50px;
    margin-bottom: 1rem;
  }

  .logoImage {
    height: 20px;
    margin-bottom: 2rem;
  }

  .content {
    width: 100%;
    max-width: 820px;
    text-align: center;
    font-size: 1.4rem;
    line-height: 1.5;
  }

  .buttonContainer {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
  }
    
  .button {
    background: #d2d2d2;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 32px;
    color: #000;
    border-radius: 25px;
    width: auto;
    text-decoration: none;
    &:hover {
      background: #000;
      color: #fff;
    }
  }

  .video {
    width: 100%;
    max-width: 1020px;
    margin-top: 2rem;
  },
`;

const VideoPage = () => {
  return (
    <Layout>
      <Container
        maxWidth={false}
        className="top-bar"
        style={{
          backgroundColor: '#a4bac5',
          width: '100vw',
          minHeight: '100vh',
        }}
      >
        <Container maxWidth="lg" className="side-padding">
          <div css={styles}>
            <img className={'iconImage'} src={`/images/roldx-icon.png`} />
            <img className={'logoImage'} src={`/images/roldx-logo.png`} />
            <div className={'content'}>
              <p>
                A glimpse of our custom method for managing our BlackBook/Rolodex of
                amazing makers. Not a messy spreadsheet, not a massive
                clunky platform either. Simple usable ui, with custom clever AI that supercharges human inputs for accurate recommendations, show-similars,
                availability reminders, and discovery of new/neglected entries.{' '}
                <br />
                <br />
                 <p>We'd love your thoughts. Shoot us an email, or answer a few Qs. </p><div className={'buttonContainer'}>
                <a href="https://us14.list-manage.com/survey?u=390bd7c3cba84e59476733905&id=86ccf35107&attribution=false" target="_blank" className={'button'}>
                  Survey
                </a>
              </div>
              </p>
  

          
            </div>

            <video
              className={'video'}
              controls
              poster="/images/roldx-ai-cover.jpg"
            >
              <source src={'/images/roldx-ai.mp4'} type="video/mp4" />
            </video>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default VideoPage;
